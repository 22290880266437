body {
  margin: 0;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  
    background-color: white;
    color: rgb(17,50,46);
 
}

html{
  background-color: white;
  color: rgb(17,50,46);

}