.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.farbe input:checked{
  background-color: #4ed164;
  border-color: #4ed164;
}

.hinter{
  background-color: white;

}

.bigger{
  font-size: 57px;
}

a{
  cursor: pointer;
}

td{
  cursor: pointer;
}

.stil{
  font-size: 25px;
}

.umrandung{
  cursor: pointer;
}

.bg-successs {
  background-color: #28a745!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hintergrund {
  background: white;
  --bs-pagination-bg: white;
  --bs-pagination-color:  white;
  background-color: white; 

}

.hintergrundd {
  background: white;
  --bs-pagination-bg: white;
  --bs-pagination-color: white;
  background-color: white; 
  height: 100vh;
}


.pagination {
  background: white;
  --bs-pagination-bg: white;
}

.form-select {
  background: white;
  --bs-pagination-bg: white;
}

.btnn {
  background: white;
  border: "1px solid black";
}



.yellow {
  background-color: yellow;
}

.blue {
  background-color: rgba(131, 229, 239, 0.8);
}